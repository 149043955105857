import React from 'react';
import SHAFPNG from '../images/shaf.png'
// import RightArrow from "../images/right-arrow.png"

import {connect} from "react-redux"
import Light from "../Theme/LightMode/theme.config"
import Dark from "../Theme/DarkMode/theme.config"

function Banner(props) {

    const theme = props.lightTheme ? Dark() : Light()

    return (
        <> 
        <div className="banner" style={{backgroundColor: theme.banner.backgroundColor}}>
            <div className="container">
                <div className="row">
                    <div className={["col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"].join(" ")}>
                        <div className="textsection">
                            <div className="pricebutton">
                                {/* <ul className="visitbutton">
                                    <li> <a target="_blank" href="https://exchange.makiswap.com/">1 MAKI = $19.12 </a> </li>
                                    <li className="ancol"> <a target="_blank" href="https://info.makiswap.com">View more assets</a> <img src={RightArrow} alt="" /> </li>
                                </ul> */}
                                <h6>Everyone can be a
                                    chef with <span>MAKI</span></h6>
                                    <p style={{color: theme.banner.color}}> The MakiSwap Protocol realigns incentives for network participants
                                     via revenue-sharing and forum-driven mechanics in tandem with the popular AMM model.</p>
                                        <ul className="enterbutton">
                                            <li><a target="_blank" rel="noreferrer" href="https://app.makiswap.com/">LAUNCH APP </a></li>
                                            <li><a target="_blank" rel="noreferrer" href="https://docs.makiswap.com/">READ DOCS </a></li>
                                        </ul>   
                            </div>
                        </div>
                    </div>
                    <div className={["col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 posre"].join(" ")}>
                        <div className="shafimg">
                        <img src={SHAFPNG} alt='maki illustration'/>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </>
    )
}

const mapStateToProps = (state) => {
  return {
    lightTheme: state.lightTheme,
  };
};

export default connect(mapStateToProps, null)(Banner);
