import Dao from "../../images/dao_light.png";
import LC from "../../images/LC_light.png";
import M6 from "../../images/M6_light.png";
import QCPCapital from "../../images/QCP_light.png";
import SL2 from "../../images/SL2_light.png";
import UnilayerCapital from "../../images/unilayerCapital_light.png";
import XendFinance from "../../images/Xend-Finance_light.png";
import Huobi_Wallet from "../../images/Huobi-wallet_light.png";

import kenetic from "../../images/kenetic.png";
import NGC from "../../images/NGC.png";
import AU21Capital from "../../images/AU21.png";
import polygon from "../../images/polygon.png";
import Defibox from "../../images/defibox.png";

export default function DarkMode() {
  return {
    dao: Dao,
    LC: LC,
    M6: M6,
    QCP: QCPCapital,
    SL2: SL2,
    unilayerCapital: UnilayerCapital,
    XendFinance: XendFinance,
    HuobiWallet: Huobi_Wallet,
    kenetic: kenetic,
    NGC: NGC,
    AU21: AU21Capital,
    poly: polygon,
    def: Defibox,

    backgroundColor: "#141826",
    header: {
      backgroundColor: "#030611",
    },
    banner: {
      color: "#ffffff",
      backgroundColor: "#141826",
    },
    Meetthechef: {
      color: "#ffffff",
      backgroundColor: "#141826",
    },
    Meetthechefbox: {
      color: "#ffffff",
      backgroundColor: "#030611",
    },
    bannerFooter: {
      backgroundColor: "#030611",
      color: "#FFFFFF",
    },
    blog: {
      backgroundColor: "#141826",
      color: "#ffffff",
      div: {
        backgroundColor: "#030611",
      },
    },
    partner: {
      backgroundColor: "#141826",
      filter:
        "invert(90%) sepia(20%) saturate(80%) hue-rotate(52deg) brightness(6000%) contrast()",
    },
    footer: {
      backgroundColor: "#030611",
      color: "#ffffff",
      logo: {
        color: "#030611",
      },
    },
  };
}
