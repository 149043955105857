const initialState = {
  lightTheme: true,
};

const reducer = (state = { initialState }, action) => {
  console.log(action);
  switch (action.type) {
    case "LIGHT_THEME": {
      return { ...state, lightTheme: action.data };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
