import React from "react";
// import kenetic from "../images/kenetic.png";
// import NGC from "../images/NGC.png";
// import AU21Capital from "../images/AU21.png";
// import polygon from "../images/polygon.png";
// import Defibox from "../images/defibox.png";
import { connect } from "react-redux";

import DarkMode from "../Theme/DarkMode/theme.config";
import LighMode from "../Theme/LightMode/theme.config";

const Partner = (props) => {
  const theme = props.lightTheme ? DarkMode() : LighMode();

  return (
    <div
      className="partner"
      style={{ backgroundColor: theme.partner.backgroundColor }}
    >
      <div>
        <h2 className="partner-para">Partnerships and Collaborations</h2>
        <div>
          <div className="partner-logo">
            <div className="partner-more-wd daomaker">
              <img
                src={theme.dao}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd kenetic">
              <img
                src={theme.kenetic}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd lc">
              <img
                src={theme.LC}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd m6">
              <img
                src={theme.M6}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd ngc">
              <img
                src={theme.NGC}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>
            <div className="partner-more-wd au21">
              <img
                src={theme.AU21}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd polygon">
              <img
                src={theme.poly}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd qcp">
              <img
                src={theme.QCP}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd sl2">
              <img
                src={theme.SL2}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>
            <div className="partner-more-wd unilayer" style={{ margin: "0" }}>
              <img
                src={theme.unilayerCapital}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd xend ">
              <img
                src={theme.XendFinance}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd huobi_wallet">
              <img
                src={theme.HuobiWallet}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>

            <div className="partner-more-wd defibox">
              <img
                src={theme.def}
                alt=""
                style={{ filter: theme.partner.filter }}
                className={props.lightTheme ? "dark_mode" : "light_mode"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lightTheme: state.lightTheme,
  };
};

export default connect(mapStateToProps, null)(Partner);
