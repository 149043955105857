import DaoMaker from "../../images/Dao_Maker.png";
import LC from "../../images/LC.png";
import M6 from "../../images/M6.png";
import QCPCapital from "../../images/QCP.png";
import SL2 from "../../images/SL2.png";
import UnilayerCapital from "../../images/unilayerCapital.png";
import XendFinance from "../../images/Xend__Finance.png";
import Huobi_Wallet from "../../images/Huobi-wallet.png";

import kenetic from "../../images/kenetic.png";
import NGC from "../../images/NGC.png";
import AU21Capital from "../../images/AU21.png";
import polygon from "../../images/polygon.png";
import Defibox from "../../images/defibox.png";

export default function LightMode() {
  return {
    dao: DaoMaker,
    LC: LC,
    M6: M6,
    QCP: QCPCapital,
    SL2: SL2,
    unilayerCapital: UnilayerCapital,
    XendFinance: XendFinance,
    HuobiWallet: Huobi_Wallet,
    kenetic: kenetic,
    NGC: NGC,
    AU21: AU21Capital,
    poly: polygon,
    def: Defibox,
    backgroundColor: "#ffffff",
    header: {
      backgroundColor: "#FFFFFF",
    },
    banner: {
      color: "#5F6471",
      backgroundColor: "#F8F9FB",
    },
    Meetthechef: {
      color: "#5F6471",
      backgroundColor: "#F8F9FB",
    },
    Meetthechefbox: {
      color: "#5F6471",
      backgroundColor: "#FFFFFF",
    },
    bannerFooter: {
      backgroundColor: "#FFFFFF",
      color: "#5F6471",
    },
    blog: {
      backgroundColor: "#F8F9FB",
      color: "#5F6471",
      div: {
        backgroundColor: "#ffffff",
      },
    },
    partner: {
      backgroundColor: "#F8F9FB",
      filter:
        "invert(39%) sepia(603%) saturate(58%) hue-rotate(185deg) brightness(46%) contrast(86%)",
    },
    footer: {
      backgroundColor: "#ffffff",
      color: "#5F6471",
      logo: {
        color: "#ffffff",
      },
    },
  };
}
