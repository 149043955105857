import React from 'react';
import Component36PNG from '../images/Component 361.png';
import Github from "../images/github.png";
import { FaTelegramPlane } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai"; // disabled (unused): AiOutlineGithub
import {GrMedium} from "react-icons/gr"

import { connect } from "react-redux";

import DarkMode from "../Theme/DarkMode/theme.config";
import LighMode from "../Theme/LightMode/theme.config";


function Footer(props) {


    const theme = props.lightTheme ? DarkMode() : LighMode();

    return (
        <div className="footer" style={{backgroundColor: theme.footer.backgroundColor, display:"flex", justifyContent:'center'}}>
                <div className="copyright_sect" >
                    <div className="footer-img">
                        <img src={Component36PNG} alt="" />
                    </div>
                    <div>
                        <p style={{color:theme.footer.color}} className="p_1">The MakiSwap Protocol realigns incentives for network participants by 
                        introducing revenue-generating and forum-driven network efforts to the popular AMM model.</p>
                        <p style={{color:theme.footer.color}} className="p_2"> &copy; 2021 Makiswap</p>
                    </div>
                    <div className="footer-logo-div">
                        
                        <div className="logo-footer">
                            <a href="http://makiswap.medium.com">
                            <GrMedium style={{color:theme.footer.logo.color}} />
                            </a>
                        </div>

                        <div className="logo-footer">
                            <a href="https://twitter.com/makiswap" target="_blank" rel="noreferrer">
                            <AiOutlineTwitter style={{color:theme.footer.logo.color}} />
                            </a>
                        </div>


                        <div className="logo-footer">
                            <a href="https://github.com/MakiSwap-Protocol" style={{background:"none"}}>
                            <img src={Github} alt="" />
                            </a>
                        </div>

                        <div className="logo-footer">
                            <a href="https://t.me/makiswap" target="_blank" rel="noreferrer">
                            <FaTelegramPlane style={{color:theme.footer.logo.color}} />
                            </a>
                        </div>
                    </div>
                </div>

                <div style={{display:"flex"}}>
                    <ul className="footer_ul_i">
                    <li style={{color:theme.footer.color}} className="ul_heading">Products</li>
                        <li><a href="https://exchange.makiswap.com/" target="_blank" rel="noreferrer" style={{color:theme.footer.color}}>Exchange</a></li>
                        <li><a href="https://info.makiswap.com/" target="_blank" rel="noreferrer" style={{color:theme.footer.color}}>Analytics</a></li>
                        <li><a href="https://app.makiswap.com/" target="_blank" rel="noreferrer" style={{color:theme.footer.color}}>Farms</a></li>
                    </ul>

                    <ul className="footer_ul_i">
                    <li style={{color:theme.footer.color}} className="ul_heading">Support</li>
                    <li> <a href="https://docs.makiswap.com/" target="_blank" rel="noreferrer" style={{color:theme.footer.color}}>Docs</a></li>
                    <li> <a href="https://discord.gg/CeWJwHn2Qn" target="_blank" rel="noreferrer" style={{color:theme.footer.color}}>Discord</a></li>
                    <li><a href="https://twitter.com/makiswap" target="_blank" rel="noreferrer" style={{color:theme.footer.color}}>Twitter</a></li>

                    </ul>
                </div>
        </div>
    )
}


const mapStateToProps = (state) => {
  return {
    lightTheme: state.lightTheme,
  };
};

export default connect(mapStateToProps, null)(Footer);
