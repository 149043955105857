import React from "react";
import { connect } from "react-redux";

// import Light from "../Theme/LightMode/theme.config";
// import Dark from "../Theme/DarkMode/theme.config";

function BannerFooter(props) {
  // const theme = props.lightTheme ? Dark() : Light();

  return (
    <>
      {/* <div
        className="banner-footer"
        style={{
          backgroundColor: theme.bannerFooter.backgroundColor,
        }}
      >
        <ul style={{ color: theme.bannerFooter.color }}>
          <span className="middle-list">
            <li>MAKI: $19.12</li>
            <li>Liquidity: 3.2b</li>
          </span>
          <span className="middle-list">
            <li>Total Volume: 35.58b</li>
            <li>Total Fees: 106,745,804</li>
          </span>
          <li>Pairs: 770</li>
        </ul>
      </div> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    lightTheme: state.lightTheme,
  };
};

export default connect(mapStateToProps, null)(BannerFooter);
