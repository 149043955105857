import React from "react";

import { FaTelegramPlane, FaDiscord } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";

const Contact = () => {
  return (
    <div className="contact">
      <div>
        <h2 className="main-para">
        Our Social Media
        </h2>
        <div
          className="soclcen"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="contact-div">
            <div className="logo-contact">
              <FaTelegramPlane fontSize="22px" />
            </div>
            <h3 className="total-follow">14,000+</h3>
            <p className="follow-txt">Followers</p>
            <a href="https://t.me/makiswap" target="_blank" rel="noreferrer">Join Our  Telegram</a>
          </div>

          <div className="contact-div">
            <div className="logo-contact">
              <AiOutlineTwitter fontSize="22px" />
            </div>
            <h3 className="total-follow">18,000+</h3>
            <p className="follow-txt">Followers</p>
            <a href="https://twitter.com/makiswap" target="_blank" rel="noreferrer">Follow Our Twitter</a>
          </div>

          <div className="contact-div">
            <div className="logo-contact">
              <FaDiscord fontSize="22px" />
            </div>
            <h3 className="total-follow">500+</h3>
            <p className="follow-txt">Followers</p>
            <a href="https://discord.gg/CeWJwHn2Qn" target="_blank" rel="noreferrer">Join Our Discord</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
