import React from "react";
import HECO from "../images/HECO.png";
import Farming from "../images/Farming.png";
import Staking from "../images/Staking.png";
import LimitOrders from "../images/Limit Orders.png";
import Pairs from "../images/Pairs.png";

import { connect } from "react-redux";
import Light from "../Theme/LightMode/theme.config";
import Dark from "../Theme/DarkMode/theme.config";

const Blog = (props) => {
  const theme = props.lightTheme ? Dark() : Light();

  return (
    <div
      className="blog-back"
      style={{ backgroundColor: theme.blog.backgroundColor }}
    >
      <div className="container">
        <div>
          <div
            className="blog-img"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <img src={HECO} alt="" />
          </div>
          <div
            className="blog-div"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <h3>QUICK & CHEAP TRADES</h3>
            <p style={{ color: theme.blog.color }}>
            Hosted natively on the <b>Huobi Eco Chain (HECO)</b>, leveraging <b>UniLayer trading tools</b> in order to help facilitate <b>interoperability</b> between Ethereum and Huobi. Take advantage of our trading <b>fees of just a fraction of a penny.</b>
            </p><br></br>
            <a target="_blank" rel="noreferrer" href="https://exchange.makiswap.com/">EXPLORE MAKISWAP</a>
          </div>
        </div>
        <div>
          <div
            className="blog-img"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <img src={Farming} alt="" />
          </div>
          <div
            className="blog-div"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <h3>LIMITED-TIME REWARDS</h3>
            <p style={{ color: theme.blog.color }}>
            Experience <b>the highest APYs on Huobi Eco Chain (HECO)</b>, with on-chain liquidity for assets such as: Polygon (<b>MATIC</b>), Polkadot (<b>DOT</b>), Bitcoin (<b>BTC</b>), Cardano (<b>ADA</b>), Chainlink (<b>LINK</b>), Aave (<b>AAVE</b>), Filecoin (<b>FIL</b>), and more!
            </p><br></br>
            <a target="_blank" rel="noreferrer" href="https://app.makiswap.com/farms">FARM FOR REWARDS</a>
          </div>
        </div>
        <div>
          <div
            className="blog-img"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <img src={Staking} alt="" />
          </div>
          <div
            className="blog-div"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <h3>COMPOUNDING RETURNS</h3>
            <p style={{ color: theme.blog.color }}>
            Enjoy <b>SOY pools for double-dipping rewards</b> for members by <b>allowing other projects to setup a SOY pool on MakiSwap</b>. With the <b>MAKI pool</b>, a certain percentage of the MAKI supply is allocated to pool rewards.
            </p><br></br>
            <a target="_blank" rel="noreferrer" href="https://app.makiswap.com/pools">COMPOUND DAILY</a>
          </div>
        </div>
        <div>
          <div
            className="blog-img"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <img src={LimitOrders} alt="" />
          </div>
          <div
            className="blog-div"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <h3>DEX LIMIT ORDERS</h3>
            <p style={{ color: theme.blog.color }}>
            Integrations planned to acquire access to <b>robust trading tools</b>, making MakiSwap the <b>first DEX with this native ability</b> built-into the exchange. This continues to capture the attention of traders from various blockchains.
            </p><br></br>
            <a target="_blank" rel="noreferrer"
              href="https://unilayer.app/"
              className="inc-wdth"
              style={{ whiteSpace: "nowrap" }}
            >LIMIT ORDERS SOON
            </a>
          </div>
        </div>
        <div>
          <div
            className="blog-img"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <img src={Pairs} alt="" />
          </div>
          <div
            className="blog-div"
            style={{ backgroundColor: theme.blog.div.backgroundColor }}
          >
            <h3>TRADE ANALYTICS</h3>
            <p style={{ color: theme.blog.color }}>
            Open-sourced <b>trade data</b> enabling users to <b>query the underlying database </b> organized by user, pair, and token. These views are configurable to weekly, monthly, and annual intervals, providing deep insights into each trade executed on our exchange.
            </p>
            <a target="_blank" rel="noreferrer" href="https://info.makiswap.com/">VIEW OUR CHARTS</a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lightTheme: state.lightTheme,
  };
};

export default connect(mapStateToProps, null)(Blog);
