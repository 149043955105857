import React from 'react';
import meetimg from '../images/meetimg.png'
import chef1 from '../images/chef1.png'
import chef2 from '../images/chef2.png'
import chef3 from '../images/chef3.png'
import chef4 from '../images/chef4.png'
import chef5 from '../images/chef5.png'
import chef6 from '../images/chef6.png'
import chef7 from '../images/chef7.png'
import chef8 from '../images/chef8.png'
import chef9 from '../images/chef9.png'
import chef10 from '../images/chef10.png'
import {connect} from "react-redux"
import Light from "../Theme/LightMode/theme.config" 
import Dark from "../Theme/DarkMode/theme.config"

function Meetthechef(props) {
    const theme = props.lightTheme ? Dark() : Light()

    return (
        <> 
        <div className="chefmain" style={{backgroundColor: theme.Meetthechef.backgroundColor}}>
            <div className="container">
            <div className="row">
                    <div className={["col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"].join(" ")}>
                        <div className="textsection">
                                <h1 className="headngh1">Meet The Team</h1>
                                <p style={{color: theme.Meetthechef.color}}> Meet our exceptionally talented team that serves you with the most delicious sushi dishes in DeFi</p>
                        </div>
                    </div>
                    <div className={["col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12 posre"].join(" ")}>
                        <div className="shafimg">
                        <img src={meetimg} alt='maki illustration' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="cheflist" style={{backgroundColor: theme.Meetthechef.backgroundColor}}>
            <div className="container">
                <div className="row">
                    <div className="teamsec">
                        <img src={chef1} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Geo</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Founder & MasterChef</p>
                        </div>
                    </div>
                    <div className="teamsec">
                        <img src={chef2} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Devair</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Co-founder</p>
                        </div>
                    </div>
                    <div className="teamsec">
                        <img src={chef5} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Khanh</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Lead Developer</p>
                        </div>
                    </div>
                    <div className="teamsec">
                        <img src={chef6} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Mohammed</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Developer</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="teamsec">
                        <img src={chef7} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Jenny</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Graphics</p>
                        </div>
                    </div>
                    <div className="teamsec">
                        <img src={chef8} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Akira</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Developer</p>
                        </div>
                    </div>
                    <div className="teamsec">
                        <img src={chef9} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Luka</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Developer</p>
                        </div>
                    </div>
                    <div className="teamsec">
                        <img src={chef10} alt='maki illustration' />
                        <div className="teamcon" style={{backgroundColor: theme.Meetthechefbox.backgroundColor}} >
                            <h3>Donnie</h3>
                            <p  style={{color: theme.Meetthechefbox.color}}>Developer</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

const mapStateToProps = (state) => {
    return {
      lightTheme: state.lightTheme,
    };
  };
  
  export default connect(mapStateToProps, null)(Meetthechef);

