import React, { useState } from "react";

// import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaRegMoon } from "react-icons/fa";
import { RiSunLine } from "react-icons/ri";
import { connect } from "react-redux";

import { light_theme } from "../Redux/action";
import DarkMode from "../Theme/DarkMode/theme.config";
import LighMode from "../Theme/LightMode/theme.config";
import headlogo from "../images/headlogo.png";

const Header = (props) => {
  const [click, setClicked] = useState(false);

  const handlleclaick = () => setClicked(!click);

  const theme = props.lightTheme ? DarkMode() : LighMode();
  return (
    <div
      className="header"
      style={{ backgroundColor: theme.header.backgroundColor }}
    >
      <div className="container">
        <nav className="navbar">
          <div className="menu-icon" onClick={handlleclaick}>
            <i
              className={click ? "fas fa-times i-style" : "fas fa-bars i-style"}
            />
          </div>

          <div className="shafimg2">
            <a className="navbar-brand" href="/" rel="noreferrer">
              <img src={headlogo} alt='maki logo' />
            </a>
          </div>

          <ul
            className={click ? "nav-menu active" : "nav-menu"}
            style={{
              backgroundColor: theme.header.backgroundColor,
            }}
          >
            <div
              class="switch"
              onClick={() => {
                props.toggle_theme(!props.lightTheme);
              }}
              style={{ cursor: "pointer" }}
            >
              <span
                className="light-span"
                style={{
                  background: props.lightTheme ? "transparent" : "#E9F2FF",
                }}
              >
                <RiSunLine
                  style={{
                    color: props.lightTheme ? "#E9F2FF" : "#0950b5",
                  }}
                />
              </span>
              <span
                className="dark-span"
                style={{
                  backgroundColor: props.lightTheme ? "#030611" : "transparent",
                }}
              >
                <FaRegMoon
                  style={{
                    color: props.lightTheme ? "#E9F2FF" : "#E9F2FF",
                  }}
                />
              </span>
            </div>

            <span className="border-navbar" />

            {/* <li className="nav-item">
              <a className="nav-link" href="/meet-the-team">
              Meet the team
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="https://info.makiswap.com/">
                Analytics
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" target="_blank" href="http://makiswap.medium.com" rel="noreferrer">
                News
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" target="_blank" href="https://t.me/MakiSwapPriceTalk" rel="noreferrer">
                TRADERS
              </a>
            </li>
          </ul> 
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lightTheme: state.lightTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_theme: (lightTheme) => dispatch(light_theme(lightTheme)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
