// import logo from "./logo.svg";

import Header from "./Components/Header";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import BannerFooter from "./Components/bannerFooter";
import Blog from "./Components/blog";
import Contact from "./Components/contact";
import Partner from "./Components/partner";
import Meetthechef from "./Components/Meetthechef";
import "./App.css";

import { store, persistor } from "./Redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="wrapper">
          <Header />
          <Router>
            <Switch>
              <Route exact path="/">
                <Banner />
                <BannerFooter />
                <Blog />
                <Contact />
                <Partner />
              </Route>
              <Route exact path="/meet-the-team">
                <Meetthechef />
              </Route>
            </Switch>
          </Router>
          <Footer />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
